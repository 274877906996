import {graphql} from 'gatsby';
import React from 'react';
import HeroModule from '../../components/HeroModule';
import Layout from '../../components/Layout';
import Module from '../../components/Module';
import SnakeModule from '../../components/SnakeModule';
import SocialModule from '../../components/SocialModule';
import VideoModule from '../../components/VideoModule';
import './landing.less';

export default ({data}) => {
  const {
    title,
    pageDescription,
    headerText,
    bullet1,
    bullet2,
    bullet3,
    snakeImage,
    snakeImageAlt,
    buttonLabel,
    buttonUrl,
  } = data.landingPagesCsv;

  return (
    <Layout pageTitle={title} description={pageDescription}>
      <HeroModule
        imageName="home-hero"
        imageAlt="Screenshot of Miter"
        background="Gradient"
        className="HomeHero LandingHero"
      >
        <div className="HomeHeroContent">
          <h1>{title}</h1>
          <div className="HomeHeroBlurb BodyXLarge">{headerText}</div>
          <div className="ButtonBar">
            <a href={buttonUrl} target="_blank" className="Button">
              {buttonLabel}
            </a>
          </div>
        </div>
      </HeroModule>

      <VideoModule />

      <SocialModule />

      <SnakeModule imageName={snakeImage} imageAlt={snakeImageAlt} imageSize="Large" forceAltBackground="Off">
        <ul className="BodyHuge">
          <li>{bullet1}</li>
          <li>{bullet2}</li>
          <li>{bullet3}</li>
        </ul>
      </SnakeModule>
      <Module compact>
        <a href={buttonUrl} target="_blank" className="Button">
          {buttonLabel}
        </a>
      </Module>
    </Layout>
  );
};

export const query = graphql`
  query MyQuery($id: String) {
    landingPagesCsv(id: {eq: $id}) {
      slug
      title
      pageDescription
      headerText
      bullet1
      bullet2
      bullet3
      snakeImage
      snakeImageAlt
      buttonLabel
      buttonUrl
    }
  }
`;
